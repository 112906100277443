import { defineStore } from 'pinia';
import { PrimeVueConfiguration } from 'primevue';
import { ThemeEnum } from '@/enums/ThemeEnum';
import { useAuthStore } from '@/modules/shared/stores/auth';
import i18n, { loadLocaleMessages, setI18nLanguage } from '@/plugins/i18n';
import { setPrimeVueLanguage } from '@/plugins/primeVue';
import { api } from '@/services/api';
import { EnumsClientPermission } from '../../generated/api';

interface IState {
  email: string;
  roles: Array<string>;
  permissions: EnumsClientPermission[];
  languageCode: string;
  isDarkMode: boolean;
  isAffiliate: boolean;
  theme: ThemeEnum;
  hostingCode: string;
  authProvider?: string;
  openFirstLoadDialog: boolean;
  openSubscriptionDialog: boolean;
  loginAsAdmin: boolean;
}

export const useUserStore = defineStore('user', {
  state: (): IState => ({
    email: '',
    roles: [],
    permissions: [],
    languageCode: '',
    isDarkMode: false,
    isAffiliate: false,
    theme: ThemeEnum.DEFAULT,
    hostingCode: '',
    authProvider: '',
    openFirstLoadDialog: true,
    openSubscriptionDialog: false,
    loginAsAdmin: false,
  }),
  getters: {
    getIsLoggedIn: () => {
      const authStore = useAuthStore();
      return !!authStore.refreshToken;
    },
  },
  actions: {
    async setLanguage(languageCode: string, primeVueInstance: { config: PrimeVueConfiguration }) {
      this.languageCode = languageCode;

      // Load locale messages
      await loadLocaleMessages(i18n, languageCode);

      // Set i18n language
      setI18nLanguage(i18n, languageCode);

      // Set primeVue language
      setPrimeVueLanguage(primeVueInstance, languageCode);
    },
    setEmail(email: string) {
      this.email = email;
    },
    setAffiliate(isAffiliate: boolean) {
      this.isAffiliate = isAffiliate;
    },
    setRole(roles: Array<string>) {
      this.roles = roles;
    },
    async getPermissions() {
      if (!this.getIsLoggedIn) {
        return;
      }

      try {
        const { data: permissions } = await api.clientAuthGetClientPermissions();

        this.setPermissions(permissions.clientPermissions || []);
      } catch (error) {
        console.error('Error fetching permissions', error);
      }
    },
    setPermissions(permissions: EnumsClientPermission[]) {
      this.permissions = permissions;
    },
    setAuthProvider(authProvider: string) {
      this.authProvider = authProvider;
    },
    destroySession() {
      // const wizardStore = useWizardStore();
      // const wizardHelperStore = useWizardHelperStore();

      this.email = '';
      this.roles = [];
      this.permissions = [];
      this.isAffiliate = false;

      // wizardStore.resetWizard();
      // wizardHelperStore.resetWizardHelper();
    },
    reset() {
      this.$reset();
    },
  },
  persist: {
    paths: ['languageCode', 'darkMode', 'theme', 'hostingCode', 'openFirstLoadDialog'],
  },
});
