import ToastEventBus from 'primevue/toasteventbus';

// TODO test and change based on API response
export const handleError = (error: any) => {
  console.log(error, 'test Error');
  if (error.response) {
    if (error.response.data.status >= 500) {
      ToastEventBus.emit('add', { severity: 'error', summary: 'Server error', life: 6000 });
    } else {
      ToastEventBus.emit('add', { severity: 'error', summary: error.response.data.detail, life: 6000 });
    }
  } else {
    console.warn(error);
  }
  return error;
};
