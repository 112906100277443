<template>
  <div class="h-screen max-h-screen overflow-hidden bg-white">
    <div v-if="wizardOnboardingStore.trialPeriodDate > 0" class="flex items-center justify-center gap-x-6 overflow-hidden bg-gray-50 px-6 py-2 text-center sm:px-3">
      <div class="mx-auto flex min-w-max items-center justify-center text-center">
        <p class="text-xs leading-6 text-main sm:text-sm">
          <strong
            ><span>{{ $t('layout.wizardLayout.trial') }}</span></strong
          >
          <span class="mx-2">{{ $t('layout.wizardLayout.trialOff') }}</span
          >{{ wizardOnboardingStore.trialPeriodDate }}
          <span class="">{{ $t('layout.wizardLayout.trialDays') }}</span>
        </p>
      </div>
    </div>
    <div class="mt-3 flex w-full items-center justify-between bg-white px-5 text-left">
      <div class="w-52">
        <router-link :to="{ name: DASHBOARD_ROUTES.INDEX }">
          <img :src="$filePath + '/logos/tanganicaLogo.svg'" alt="logo" class="w-32" />
        </router-link>
      </div>
      <div class="w-full rounded-lg px-10">
        <ProgressBar :value="8"></ProgressBar>
      </div>
      <div class="mt-3 hidden w-fit items-center">
        <nav class="w-full" aria-label="Progress">
          <div role="list" class="flex items-center space-x-4 space-y-0 align-baseline">
            <div v-for="(step, index) in steps" :key="step.id" class="">
              <button @click="switchStep(index)" :disabled="!canNavigateToStep(index)" :class="stepClass(index)" aria-current="step">
                <span class="text-sm font-medium" :class="stepTextClass(index)">
                  <FontAwesomeIcon v-if="step.isValid" icon="fa fa-question-circle" size="" class="h-5 w-5"></FontAwesomeIcon>
                  <span class="" v-else :class="stepNumberClass(index)">
                    <span class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-[0.62rem]">{{ index + 1 }}</span>
                  </span>
                </span>
              </button>
            </div>
          </div>
        </nav>
      </div>
      <div class="flex items-center gap-x-8">
        <div class="z-10 ml-auto mr-0 hidden w-fit lg:block">
          <SelectLanguage :saveToDb="true"></SelectLanguage>
        </div>
        <router-link :to="{ name: DASHBOARD_ROUTES.INDEX }" class="ml-auto mr-0 block w-6">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="my-auto w-6 font-extrabold text-primary-900 sm:h-8 sm:w-8">
            <path d="M11.47 3.84a.75.75 0 011.06 0l8.69 8.69a.75.75 0 101.06-1.06l-8.689-8.69a2.25 2.25 0 00-3.182 0l-8.69 8.69a.75.75 0 001.061 1.06l8.69-8.69z" />
            <path
              d="M12 5.432l8.159 8.159c.03.03.06.058.091.086v6.198c0 1.035-.84 1.875-1.875 1.875H15a.75.75 0 01-.75-.75v-4.5a.75.75 0 00-.75-.75h-3a.75.75 0 00-.75.75V21a.75.75 0 01-.75.75H5.625a1.875 1.875 0 01-1.875-1.875v-6.198a2.29 2.29 0 00.091-.086L12 5.43z"
            />
          </svg>
        </router-link>
      </div>
    </div>
    <div class="z-10 ml-auto mr-0 mt-3 hidden w-fit">
      <SelectLanguage :saveToDb="true"></SelectLanguage>
    </div>

    <router-view></router-view>
  </div>
</template>

<script setup lang="ts">
import { PhoneNumberUtil } from 'google-libphonenumber';
import ProgressBar from 'primevue/progressbar';
import { useToast } from 'primevue/usetoast';
import { computed, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { DASHBOARD_ROUTES } from '@/modules/dashboard/enums/RoutesEnum';
import SelectLanguage from '@/modules/shared/components/SelectLanguage.vue';
import { WIZARD_ROUTES } from '@/modules/wizardOnboarding/enums/RoutesEnum';
import { useWizardOnboardingStore } from '@/modules/wizardOnboarding/stores/wizardOnboarding';
// import { useEshopsStore } from '@/stores/eshops';
import { useLoadingStore } from '@/stores/loading';
import { useWizardHelperStore } from '@/stores/wizardHelper';
import { findLastIndex } from '@/utils/findLastIndex';

const { t } = useI18n();
const router = useRouter();
const wizardOnboardingStore = useWizardOnboardingStore();
const wizardHelperStore = useWizardHelperStore();
const loadingStore = useLoadingStore();
// const eshopsStore = useEshopsStore();
const toast = useToast();

const phoneUtil: PhoneNumberUtil = PhoneNumberUtil.getInstance();

onMounted(async () => {
  // if (!wizardHelperStore.isNewEshop && eshopsStore.managedEshops.length > 0) {
  await initWizard();
  await redirectToActiveStep();
  // }
});

const canUserNavigateInsideApp = computed(() => {
  // TODO: Implement ESHOP STORE
  // return eshopsStore.managedEshops.some((eshop) => eshop.wizardStatus === EnumsWizardStatus.AddedPixel);
  return true;
});

const steps = computed(() => [
  {
    id: 1,
    name: 'xmlfeed',
    href: WIZARD_ROUTES.XML_FEED,
    isValid: wizardOnboardingStore.steps[0].isValid,
  },
  {
    id: 2,
    name: t('layout.wizardLayout.billingInfo'),
    href: WIZARD_ROUTES.BILLINFO_PAGE,
    isValid: wizardOnboardingStore.steps[1].isValid,
  },
  {
    id: 3,
    name: t('layout.wizardLayout.productFeed'),
    href: WIZARD_ROUTES.XML_PAGE,
    isValid: wizardOnboardingStore.steps[2].isValid,
  },
  {
    id: 4,
    name: t('layout.wizardLayout.measuringCode'),
    href: WIZARD_ROUTES.PIXEL_PAGE,
    isValid: wizardOnboardingStore.steps[3].isValid,
  },
  {
    id: 5,
    name: 'xmlfeed',
    href: WIZARD_ROUTES.XML_FEED,
    isValid: wizardOnboardingStore.steps[0].isValid,
  },
  {
    id: 6,
    name: t('layout.wizardLayout.billingInfo'),
    href: WIZARD_ROUTES.BILLINFO_PAGE,
    isValid: wizardOnboardingStore.steps[1].isValid,
  },
  {
    id: 7,
    name: t('layout.wizardLayout.productFeed'),
    href: WIZARD_ROUTES.XML_PAGE,
    isValid: wizardOnboardingStore.steps[2].isValid,
  },
  {
    id: 8,
    name: t('layout.wizardLayout.measuringCode'),
    href: WIZARD_ROUTES.PIXEL_PAGE,
    isValid: wizardOnboardingStore.steps[3].isValid,
  },
  {
    id: 9,
    name: t('layout.wizardLayout.measuringCode'),
    href: WIZARD_ROUTES.PIXEL_PAGE,
    isValid: wizardOnboardingStore.steps[3].isValid,
  },
  {
    id: 10,
    name: 'xmlfeed',
    href: WIZARD_ROUTES.XML_FEED,
    isValid: wizardOnboardingStore.steps[0].isValid,
  },
  {
    id: 11,
    name: t('layout.wizardLayout.billingInfo'),
    href: WIZARD_ROUTES.BILLINFO_PAGE,
    isValid: wizardOnboardingStore.steps[1].isValid,
  },
  {
    id: 12,
    name: t('layout.wizardLayout.productFeed'),
    href: WIZARD_ROUTES.XML_PAGE,
    isValid: wizardOnboardingStore.steps[2].isValid,
  },
  {
    id: 13,
    name: t('layout.wizardLayout.measuringCode'),
    href: WIZARD_ROUTES.PIXEL_PAGE,
    isValid: wizardOnboardingStore.steps[3].isValid,
  },
]);

const initXml = async () => {
  try {
    // TODO: Implement API call
    // const { data: xmlFeed } = await api.clientWizardGetProductFeedUrl();
    const xmlFeed = '';
    wizardOnboardingStore.steps[2].values.xmlFeed = xmlFeed || '';

    // Set valid
    wizardOnboardingStore.steps[2].isValid = wizardOnboardingStore.steps[2].values.xmlFeed !== '';
  } catch (error) {
    wizardOnboardingStore.steps[2].isValid = false;
    throw new Error();
  }
};

const initWizard = async () => {
  loadingStore.updateLoading(true);
  try {
    await initXml();
  } catch (error: any) {
    if (error.response) {
      if (error.response.data.status >= 500) {
        toast.add({
          severity: 'error',
          summary: t('serverErrorTitle'),
          detail: error.response.data.requestId,
          life: 20000,
        });
      } else {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          life: 6000,
        });
      }
    } else {
      console.warn(error);
    }
  } finally {
    loadingStore.updateLoading(false);
  }
};

const stepClass = (index: number) => {
  if (index !== wizardOnboardingStore.getCurrentStep && steps.value[index]?.isValid) {
    return 'w-full group flex flex-col justify-center border-green-400 py-1 px-1 hover:border-green-600 border-t-4 pt-2';
  } else if (index === wizardOnboardingStore.getCurrentStep) {
    return 'w-full group flex flex-col justify-center border-primary-900 py-1 px-1 border-t-4 pt-2';
  } else {
    return 'w-full group flex flex-col justify-center border-gray-200 py-1 px-1 hover:border-gray-300 border-t-4 pt-2';
  }
};

const stepTextClass = (index: number) => {
  if (index !== wizardOnboardingStore.getCurrentStep && steps.value[index]?.isValid) {
    return 'text-green-600';
  } else if (index === wizardOnboardingStore.getCurrentStep) {
    return 'text-main';
  } else {
    return 'text-gray-500';
  }
};

const stepNumberClass = (index: number) => {
  if (index !== wizardOnboardingStore.getCurrentStep && steps.value[index]?.isValid) {
    return 'h-5 w-5 text-center block sm:flex flex-shrink-0 items-center justify-center rounded-full border border-green-400 hover:border-green-600  relative';
  } else if (index === wizardOnboardingStore.getCurrentStep) {
    return 'h-5 w-5 text-center block sm:flex flex-shrink-0 items-center justify-center rounded-full border border-main  relative';
  } else {
    return 'h-5 w-5 text-center block sm:flex flex-shrink-0 items-center justify-center rounded-full border relative';
  }
};

const switchStep = async (index: number) => {
  await router.push({ name: steps.value[index]?.href });
};

const canNavigateToStep = (stepIndex: number) => {
  // If all steps before current step are valid, allow navigation
  return steps.value.slice(0, stepIndex).every((step) => step.isValid);
};

const redirectToActiveStep = async () => {
  const lastValidStepIndex = findLastIndex(steps.value, (step) => step.isValid);
  if (lastValidStepIndex === -1) {
    await router.push({ name: steps.value[0]?.href });
  }
  await router.push({ name: steps.value[lastValidStepIndex + 1]?.href });
};

const handlePopstate = () => {};
</script>

<style lang="css" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
