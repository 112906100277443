import { library } from '@fortawesome/fontawesome-svg-core'
import { faFacebookF, faXTwitter, faInstagram, faApple, faGoogle, faSquareFacebook, faSquareInstagram, faSquareXTwitter } from '@fortawesome/free-brands-svg-icons';
import { faGem } from '@fortawesome/free-regular-svg-icons'
import { faCreditCard, faUserSecret, faCheckCircle, faHome, faBars, faCircleUser, faRightFromBracket, faCircleDot, faTableColumns, faWandSparkles, faChevronDown, faChevronUp, faCircleArrowLeft, faHouse, faArrowLeft, faArrowRight, faQuestionCircle, faUserCircle, faCircleCheck, faArrowsLeftRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(
    faFacebookF,
    faXTwitter,
    faInstagram,
    faApple,
    faGoogle,
    faSquareFacebook,
    faSquareInstagram,
    faSquareXTwitter,
    faUserSecret,
    faCheckCircle,
    faHome,
    faBars,
    faCircleDot,
    faCircleUser,
    faRightFromBracket,
    faTableColumns,
    faWandSparkles,
    faChevronDown,
    faChevronUp,
    faCircleArrowLeft,
    faHouse,
    faArrowLeft,
    faArrowRight,
    faQuestionCircle,
    faUserCircle,
    faCreditCard,
    faCircleCheck,
    faGem,
    faArrowsLeftRight
);

export default FontAwesomeIcon;
