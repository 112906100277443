interface IQueue {
    running: any;
    autorun: boolean;
    queue: Array<Promise<any>>;
}

export default class Queue implements IQueue {
    running: any;
    autorun: boolean;
    queue: Array<any>;

    constructor(autorun = true, queue = []) {
        this.running = null;
        this.autorun = autorun;
        this.queue = queue;
    }

    add(cb) {
        this.queue.push((value) => {
            const finished = new Promise((resolve, reject) => {
                try {
                    const callbackResponse = cb(value);
                    if (callbackResponse !== false) {
                        resolve(callbackResponse);
                    } else {
                        reject(new Error('Callback returned false'));
                    }
                } catch (error) {
                    reject(error);
                }
            });
            finished.then(this.dequeue.bind(this)).catch((error) => {
                console.error('Error executing callback:', error.message);
                this.dequeue();
            });
        });

        if (this.autorun && !this.running) {
            this.dequeue();
        }

        return this;
    }

    dequeue(value?) {
        this.running = this.queue.shift();

        if (this.running) {
            this.running(value);
        }
        return this.running;
    }

    drain() {
        while (this.queue.length) {
            this.dequeue();
        }
    }

    reset() {
        this.queue = [];
        this.running = null;
        return this;
    }
}
