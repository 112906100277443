import { RouteRecordRaw } from 'vue-router';
import { WIZARD_ROUTES } from '@/modules/wizard/enums/RoutesEnum';
import FullPageLayout from '@/oldLayout/FullPageLayout.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: 'wizard',
    meta: {
      module: 'wizard',
      layout: FullPageLayout,
      quest: false,
      client: true,
    },
    children: [
      {
        path: '',
        name: WIZARD_ROUTES.INDEX,
        redirect: { name: WIZARD_ROUTES.ESHOP },
        component: () => import('@/modules/wizard/pages/Index.vue'),
        children: [
          {
            path: 'eshop',
            name: WIZARD_ROUTES.ESHOP,
            component: () => import('@/modules/wizard/pages/Eshop.vue'),
          },
          {
            path: 'country',
            name: WIZARD_ROUTES.COUNTRY,
            component: () => import('@/modules/wizard/pages/Country.vue'),
          },
          {
            path: 'phone',
            name: WIZARD_ROUTES.PHONE,
            component: () => import('@/modules/wizard/pages/Phone.vue'),
          },
        ],
      },
    ],
  },
];

export default routes;
