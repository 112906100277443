import { useJwt } from '@vueuse/integrations/useJwt';
import { ACCOUNT_ROUTES } from '@/modules/account/enums/RoutesEnum';
import { DASHBOARD_ROUTES } from '@/modules/dashboard/enums/RoutesEnum';
import { useAuthStore } from '@/modules/shared/stores/auth';
import { useEshopsStore } from '@/modules/shared/stores/eshops';
import { useUserStore } from '@/modules/shared/stores/user';
import router from '@/router';
import { api } from '@/services/api/index';
import Queue from '@/services/api/queue';

class UnauthorizedError extends Error {
  name;
  response;
  constructor(message) {
    super(message);
    this.name = 'UnauthorizedError';
    this.response = {
      data: {
        detail: message,
      },
    };
  }
}

class ForbiddenError extends Error {
  name;
  response;

  constructor(message) {
    super(message);
    this.name = 'ForbiddenError';
    this.response = {
      data: {
        detail: message,
      },
    };
  }
}

const queue = new Queue(false);
let refreshing = false;
let refreshError = false;

const checkRefreshToken = async () => {
  const authStore = useAuthStore();
  const refreshToken = authStore.refreshToken;

  if (!refreshToken) {
    refreshError = true;
    console.warn('Refresh token not found');
    return false;
  }

  const { payload } = useJwt(refreshToken);
  const refreshTokenExpiration = payload.value?.exp;

  if (!refreshTokenExpiration || Math.floor(Date.now() / 1000) >= refreshTokenExpiration) {
    refreshError = true;
    console.warn('Refresh token expired');
    return false;
  } else {
    return true;
  }
};

const checkAndAddAccessTokenToHeader = async (config) => {
  const authStore = useAuthStore();

  const accessToken = config.metadata?.accessToken === 'user' ? authStore.userAccessToken : authStore.eshopAccessToken;

  if (!accessToken) {
    console.warn('Access token not found');
    return false;
  }

  const { payload } = useJwt(accessToken);
  const accessTokenExpiration = payload.value?.exp;
  // If the access token has not expired, return config
  if (accessTokenExpiration && Math.floor(Date.now() / 1000) < accessTokenExpiration) {
    // Add access token to the request config only if not already present
    if (!config.headers.Authorization) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    return true;
  } else {
    console.warn('Access token expired');
    return false;
  }
};

const refreshAccessToken = async (config) => {
  const authStore = useAuthStore();
  const userStore = useUserStore();
  const eshopsStore = useEshopsStore();

  // If it has expired, and we are not updating it, start that now
  if (!refreshing) {
    refreshing = true;

    let newAccessToken;

    if (config.metadata?.accessToken === 'user') {
      try {
        const { data: clientAuthGetUserAccessTokenResponse } = await api.clientAuthGetUserAccessToken({
          headers: {
            Authorization: `Bearer ${authStore.refreshToken}`,
          },
          metadata: {
            skipTokenCheck: true,
          },
        });

        newAccessToken = clientAuthGetUserAccessTokenResponse?.accessToken;

        if (!newAccessToken) {
          queue.reset();
          refreshing = false;
          refreshError = true;
          console.warn('Error refreshing user access token:', clientAuthGetUserAccessTokenResponse);
          return false;
        }

        // Update email, affiliate and provider from token
        const { payload } = useJwt(newAccessToken);
        const decodedToken = payload.value;
        if (decodedToken) {
          userStore.setEmail(decodedToken.email);
          userStore.setAffiliate(decodedToken.role.includes('Affil'));
          userStore.setAuthProvider(decodedToken.AuthProvider);
        }

        authStore.setUserAccessToken(newAccessToken);
      } catch (error) {
        queue.reset();
        refreshing = false;
        refreshError = true;
        console.warn('Error refreshing user access token:', error);
        return false;
      }
    } else {
      try {
        const { data: clientAuthGetEshopAccessTokenResponse } = await api.clientAuthGetEshopAccessToken(
          {
            eshopId: eshopsStore.getSelectedEshop?.id || '',
          },
          {
            headers: {
              Authorization: `Bearer ${authStore.refreshToken}`,
            },
            metadata: {
              skipTokenCheck: true,
            },
          }
        );

        newAccessToken = clientAuthGetEshopAccessTokenResponse?.accessToken;

        if (!newAccessToken) {
          queue.reset();
          refreshing = false;
          refreshError = true;
          console.warn('Error refreshing eshop access token:', clientAuthGetEshopAccessTokenResponse);
          return false;
        }

        authStore.setEshopAccessToken(newAccessToken);
      } catch (error) {
        queue.reset();
        refreshing = false;
        refreshError = true;
        console.warn('Error refreshing eshop access token:', error);
        return false;
      }
    }

    // Add new access token to the request config
    config.headers.Authorization = `Bearer ${newAccessToken}`;

    // Set refreshing to false after successful token refresh
    refreshing = false;

    // Execute all the queued requests
    queue.drain();

    return true;
  } else {
    // Some other call already triggered of the refresh token
    // so lets add this to the queue to be called once the
    // token is done refreshing
    await new Promise((resolve) => {
      queue.add(function () {
        const token = config.metadata?.accessToken === 'user' ? authStore.userAccessToken : authStore.eshopAccessToken;

        config.headers.Authorization = `Bearer ${token}`;
        resolve(true);
      });
    });
    return true;
  }
};

const abortRequest = (abortController) => {
  abortController.abort();
};

const redirectToLogout = async () => {
  await router.push({ name: ACCOUNT_ROUTES.LOGOUT });
  refreshError = false;
};

const checkAndHandleRefreshToken = async () => {
  return await checkRefreshToken();
};

const checkAndHandleAccessToken = async (config) => {
  const accessTokenValid = await checkAndAddAccessTokenToHeader(config);
  if (!accessTokenValid) {
    const refreshed = await refreshAccessToken(config);
    if (!refreshed) {
      return false;
    }
  }
  return true;
};

export const requestInterceptor = async (config) => {
  if (refreshError) return;

  const userStore = useUserStore();
  config.headers['Accept-Language'] = userStore.languageCode;

  const abortController = new AbortController();
  setTimeout(() => abortController.abort(), 100000); // Request timeout
  config.signal = abortController.signal;

  // Skip token checks if specified
  if (config.metadata?.skipTokenCheck) return config;

  // Check refresh and access tokens based on conditions
  const shouldSkipRefreshTokenCheck = config.metadata?.skipRefreshTokenCheck ?? false;
  const shouldSkipAccessTokenCheck = config.metadata?.skipAccessTokenCheck ?? false;

  if (!shouldSkipRefreshTokenCheck && !shouldSkipAccessTokenCheck) {
    // Check both tokens (refresh + access)
    const refreshTokenValid = await checkAndHandleRefreshToken();
    if (!refreshTokenValid) {
      abortRequest(abortController);
      await redirectToLogout();
      return Promise.reject(new Error('Refresh token is not valid'));
    }

    const accessTokenValid = await checkAndHandleAccessToken(config);
    if (!accessTokenValid) {
      abortRequest(abortController);
      await redirectToLogout();
      return Promise.reject(new Error('Access token refresh failed'));
    }
  } else if (!shouldSkipAccessTokenCheck && shouldSkipRefreshTokenCheck) {
    // Only check access token
    const accessTokenValid = await checkAndHandleAccessToken(config);
    if (!accessTokenValid) {
      abortRequest(abortController);
      await redirectToLogout();
      return Promise.reject(new Error('Access token refresh failed'));
    }
  } else if (!shouldSkipRefreshTokenCheck && shouldSkipAccessTokenCheck) {
    // Only check refresh token
    const refreshTokenValid = await checkAndHandleRefreshToken();
    if (!refreshTokenValid) {
      abortRequest(abortController);
      await redirectToLogout();
      return Promise.reject(new Error('Refresh token is not valid'));
    }
  }

  return config;
};

export const errorInterceptor = async (error) => {
  if (error.response && error.response.status === 401) {
    try {
      await router.push({
        name: ACCOUNT_ROUTES.LOGOUT,
      });
    } catch (e) {
      console.error(e);
    }
    throw new UnauthorizedError('User is not authorized. Redirected to logout.');
  } else if (error.response && error.response.status === 403) {
    try {
      await router.push({
        name: DASHBOARD_ROUTES.INDEX,
      });
    } catch (e) {
      console.error(e);
    }
    throw new ForbiddenError('User permissions have changed. Redirected to dashboard.');
  } else {
    return Promise.reject(error);
  }
};
