import { RouteRecordRaw } from 'vue-router';
import ClientLayout from '@/layout/ClientLayout.vue';
import { DASHBOARD_ROUTES } from '@/modules/dashboard/enums/RoutesEnum';

const routes: Array<RouteRecordRaw> = [
  {
    path: 'dashboard',
    meta: {
      module: 'dashboard',
      layout: ClientLayout,
      guest: false,
      client: true,
    },
    children: [
      {
        path: '',
        name: DASHBOARD_ROUTES.INDEX,
        component: () => import('@/modules/dashboard/pages/Dashboard.vue'),
      },
    ],
  },
];

export default routes;
