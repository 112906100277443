export enum CREDIT_ROUTES {
    INDEX = 'routesCreditIndex',
    RECHARGE = 'routesWizardCreditRecharge',
    SUBSCRIPTION = 'routesWizardCreditSubscription',
    PAYMENT_HISTORY = 'routesWizardCreditPaymentHistory',
    BILL_INFO = 'routesWizardCreditBillInfo',
    GATEWAY = 'routesWizardCreditGateway',
    BANK = 'routesWizardCreditBank',
    AUTOMATIC = 'routesWizardCreditAutomatic',
}
