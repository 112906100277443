import ToastService from 'primevue/toastservice';
import Tooltip from 'primevue/tooltip';
import { createApp } from 'vue';
import { useUserStore } from '@/modules/shared/stores/user';
import { installPrimeVue } from '@/plugins/primeVue';
import gtm from '@/services/gtm';
import App from './App.vue';
import i18n from './plugins/i18n';
import pinia from './plugins/pinia';
import themesPlugin from './plugins/themes';
import router from './router/index';
import FontAwesomeIcon from './utils/fontAwesomeIcons';
import './assets/tailwind.css';
import './plugins/veeValidate';

(async function init() {
  const app = createApp(App);

  app.config.globalProperties.$filePath = import.meta.env.VITE_FILE_SYSTEM_URL;

  installPrimeVue(app);

  app.use(gtm);
  app.use(i18n);
  app.use(ToastService);
  app.use(pinia);

  const userStore = useUserStore();
  await userStore.getPermissions();

  app.use(router);
  app.use(themesPlugin);

  // Directives
  app.directive('tooltip', Tooltip);

  app.component('FontAwesomeIcon', FontAwesomeIcon);

  app.mount('#app');
})();
