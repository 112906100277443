import { RouteRecordRaw } from 'vue-router';
import ClientLayout from '@/layout/ClientLayout.vue';
import { STATISTIC_ROUTES } from '@/modules/statistic/enums/RoutesEnum';
const routes: Array<RouteRecordRaw> = [
  {
    path: 'statistic',
    meta: {
      module: 'statistic',
      layout: ClientLayout,
      quest: false,
      client: true,
    },
    name: STATISTIC_ROUTES.INDEX,
    redirect: { name: STATISTIC_ROUTES.CAMPAIGN_STATISTICS_PAGE },
    component: () => import('@/modules/statistic/pages/Index.vue'),
    children: [
      {
        path: 'campaign',
        name: STATISTIC_ROUTES.CAMPAIGN_STATISTICS_PAGE,
        component: () => import('@/modules/statistic/pages/CampaignStatistics.vue'),
      },
      {
        path: 'order',
        name: STATISTIC_ROUTES.ORDERS_STATISTICS_PAGE,
        component: () => import('@/modules/statistic/pages/OrdersStatistics.vue'),
      },
      {
        path: 'product',
        name: STATISTIC_ROUTES.PRODUCTS_STATISTICS_PAGE,
        component: () => import('@/modules/statistic/pages/ProductStatistics.vue'),
      },
      {
        path: 'category',
        name: STATISTIC_ROUTES.CATEGORY_STATISTICS_PAGE,
        component: () => import('@/modules/statistic/pages/CategoryStatistics.vue'),
      },
      {
        path: 'traffic',
        name: STATISTIC_ROUTES.TRAFFIC_STATISTICS_PAGE,
        component: () => import('@/modules/statistic/pages/TrafficStatistics.vue'),
      },
      {
        path: 'competitive',
        name: STATISTIC_ROUTES.COMPETITIVE_STATISTICS_PAGE,
        component: () => import('@/modules/statistic/pages/CompetitiveMetricStatistics.vue'),
      },
    ],
  },
];

export default routes;
