import { RouteRecordRaw } from 'vue-router';
import { WIZARD_PIXEL_ROUTES } from '@/modules/wizardPixel/enums/RoutesEnum';
import FullPageLayout from '@/oldLayout/FullPageLayout.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: 'wizardPixel',
    meta: {
      module: 'wizardPixel',
      layout: FullPageLayout,
      quest: false,
      client: true,
    },
    children: [
      {
        path: '',
        name: WIZARD_PIXEL_ROUTES.INDEX,
        redirect: { name: WIZARD_PIXEL_ROUTES.PIXEL },
        component: () => import('@/modules/wizardPixel/pages/Index.vue'),
        children: [
          {
            path: 'firstStep',
            name: WIZARD_PIXEL_ROUTES.PIXEL,
            component: () => import('@/modules/wizardPixel/pages/Pixel.vue'),
          },
        ],
      },
    ],
  },
];

export default routes;
