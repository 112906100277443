import { RouteRecordRaw } from 'vue-router';
import WizardLayout from '@/layout/WizardOnboardingLayout.vue';
import { WIZARD_ROUTES } from '@/modules/wizardOnboarding/enums/RoutesEnum';

const routes: Array<RouteRecordRaw> = [
  {
    path: 'wizard',
    meta: {
      module: 'wizard',
      layout: WizardLayout,
      quest: false,
      client: true,
    },
    children: [
          {
            path: 'xmlFeed',
            name: WIZARD_ROUTES.XML_FEED,
            component: () => import('@/modules/wizardOnboarding/pages/WizardXmlFeed.vue'),
          },
          {
            path: 'pixel',
            name: WIZARD_ROUTES.PIXEL,
            component: () => import('@/modules/wizardOnboarding/pages/WizardPixel.vue'),
          },
          {
            path: 'billInfo',
            name: WIZARD_ROUTES.BILL_INFO,
            component: () => import('@/modules/wizardOnboarding/pages/WizardBillInfo.vue'),
          },
        ],
  },
];

export default routes;
