import { createWebHistory, createRouter } from 'vue-router';
import { ACCOUNT_ROUTES } from '@/modules/account/enums/RoutesEnum';
import accountRoutes from '@/modules/account/router/routes';
import creditRoutes from '@/modules/credit/router/routes';
import { DASHBOARD_ROUTES } from '@/modules/dashboard/enums/RoutesEnum';
import dashboardRoutes from '@/modules/dashboard/router/routes';
import { useUserStore } from '@/modules/shared/stores/user';
import statisticRoutes from '@/modules/statistic/router/routes';
import wizardRoutes from '@/modules/wizard/router/routes';
import wizardCreditRoutes from '@/modules/wizardCredit/router/routes';
import wizardOnBoardingRoutes from '@/modules/wizardOnboarding/router/routes';
import wizardPixelRoutes from '@/modules/wizardPixel/router/routes';
import wizardXmlFeedRoutes from '@/modules/wizardXmlFeed/router/routes';
import i18n, { loadLocaleMessages } from '@/plugins/i18n';

const routes = [
  {
    path: '/:lang?',
    children: [...accountRoutes, ...wizardRoutes, ...dashboardRoutes, ...statisticRoutes, ...wizardXmlFeedRoutes, ...wizardPixelRoutes, ...wizardCreditRoutes, ...creditRoutes, ...wizardOnBoardingRoutes],
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: () => import('@/modules/account/pages/Login.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  const userStore = useUserStore();
  const langParameter = userStore.languageCode;

  // If the route doesn't have a language parameter or language is different, redirect to the same route with the correct language
  if (!to.params.lang || to.params.lang !== langParameter) {
    return next({
      name: to.name as string,
      params: {
        ...to.params,
        lang: langParameter,
      },
      query: to.query,
    });
  }

  // Redirect logic
  if (to.meta.client && !to.meta.guest && !userStore.getIsLoggedIn) {
    next({
      name: ACCOUNT_ROUTES.LOGIN,
      params: { lang: langParameter },
    });
  } else if (!to.meta.client && to.meta.guest && userStore.getIsLoggedIn) {
    next({
      name: DASHBOARD_ROUTES.INDEX,
      params: { lang: langParameter },
    });
  } else {
    await loadLocaleMessages(i18n, userStore.languageCode, to.meta?.module);

    if (to.meta.permissions) {
      const permissions = userStore.permissions;

      if (to.meta.permissions.condition === 'AND') {
        if (to.meta.permissions.list.every((permission) => permissions.includes(permission))) {
          next();
        } else {
          userStore.openSubscriptionDialog = !userStore.openSubscriptionDialog;
          next({
            name: from.name as string,
          });
        }
      } else {
        if (to.meta.permissions.list.some((permission) => permissions.includes(permission))) {
          next();
        } else {
          userStore.openSubscriptionDialog = !userStore.openSubscriptionDialog;
          next({
            name: from.name as string,
          });
        }
      }
    } else {
      next();
    }
  }
});

export default router;
