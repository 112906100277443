import { RouteRecordRaw } from 'vue-router';
import { ACCOUNT_ROUTES } from '@/modules/account/enums/RoutesEnum';
import FullPageLayout from "@/oldLayout/FullPageLayout.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    meta: {
      module: 'account',
      layout: FullPageLayout,
    },
    children: [
      {
        path: '',
        name: ACCOUNT_ROUTES.LOGIN,
        component: () => import('@/modules/account/pages/Login.vue'),
        meta: {
          guest: true,
          client: false,
        },
      },
      {
        path: 'register',
        name: ACCOUNT_ROUTES.REGISTER,
        component: () => import('@/modules/account/pages/Registration.vue'),
        meta: {
          guest: true,
          client: false,
        },
      },
      {
        path: 'list-eshops',
        name: ACCOUNT_ROUTES.LIST_OF_ESHOPS,
        component: () => import('@/modules/account/pages/ListOfEshops.vue'),
        meta: {
          guest: false,
          client: true,
        },
      },
      {
        path: 'logout',
        name: ACCOUNT_ROUTES.LOGOUT,
        component: () => import('@/modules/account/pages/Logout.vue'),
        meta: {
          guest: false,
          client: true,
        },
      },
    ],
  },
];

export default routes;
