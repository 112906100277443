<template>
  <Select :model-value="selectedEshop" @change="onEshopChange" :options="managedEshops" class="w-full justify-items-center" :ptOptions="{ mergeProps: true }" :pt="{}">
    <template #value="slotProps">
      <div v-if="slotProps.value" class="align-items-center flex items-center">
        <div class="truncate">{{ slotProps.value.domainName }}</div>
        <img v-if="slotProps.value.country" :src="`${$filePath}/flags/${slotProps.value.country}.png`" alt="..." class="ml-1 inline-block h-3 w-3 sm:h-5 sm:w-5" />
        <img v-if="slotProps.value.isAffil" :src="`${$filePath}/icons/affiliate.png`" alt="..." class="ml-1 inline-block h-3 w-auto sm:h-4" />
        <img v-if="slotProps.value.hasActiveSubscription" :src="$filePath + '/icons/premium.svg'" class="ml-1 inline-block h-4" />
      </div>
      <span v-else>
        {{ slotProps.placeholder }}
      </span>
    </template>
    <template #option="slotProps">
      <div class="align-items-center flex items-center">
        <div class="truncate">{{ slotProps.option.domainName }}</div>
        <img v-if="slotProps.option.country" :src="`${$filePath}/flags/${slotProps.option.country}.png`" alt="..." class="ml-1 inline-block h-3 w-3 sm:h-5 sm:w-5" />
        <img v-if="slotProps.option.isAffil" :src="`${$filePath}/icons/affiliate.png`" alt="..." class="ml-1 inline-block h-3 w-auto sm:h-4" />
        <img v-if="slotProps.option.hasActiveSubscription" :src="$filePath + '/icons/premium.svg'" class="ml-1 inline-block h-4" />
      </div>
    </template>
  </Select>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import Select, { SelectChangeEvent } from 'primevue/select';
import { useEshopsStore } from '@/modules/shared/stores/eshops';
import { ClientEshopListEshopInfo } from '../../../../generated/api';

const eshopsStore = useEshopsStore();

const { selectedEshop, managedEshops } = storeToRefs(eshopsStore);

const onEshopChange = async (changeEvent: SelectChangeEvent) => {
  if (!changeEvent.value) {
    return;
  }

  const eshop = changeEvent.value as ClientEshopListEshopInfo;

  if (eshop) {
    const isSelected = await eshopsStore.setSelectedEshop(eshop);
    if (!isSelected) return;
  }
};
</script>
