<template>
  <Select v-model="selectedLanguage" @change="onChange" :options="LanguagesCodebook" optionLabel="text" class="justify-items-center rounded-md">
    <template #value="slotProps">
      <div class="align-items-center flex">
        <img :alt="slotProps.value.text" :src="`${$filePath}/${slotProps.value.flag}`" class="mr-2 h-6 w-6 rounded-md" />
        <div v-show="props.showText">{{ slotProps.value.text }}</div>
      </div>
    </template>
    <template #option="slotProps">
      <div class="align-items-center flex">
        <img :alt="slotProps.option.text" :src="`${$filePath}/${slotProps.option.flag}`" class="mr-2 h-6 w-6" />
        <div v-show="props.showText">{{ slotProps.option.text }}</div>
      </div>
    </template>
  </Select>
</template>

<script setup lang="ts">
import { usePrimeVue } from 'primevue/config';
import Select, { SelectChangeEvent } from 'primevue/select';
import { useToast } from 'primevue/usetoast';
import { computed, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import LanguagesCodebook, { ILanguage } from '@/codebooks/LanguagesCodebook';
import { useUserStore } from '@/modules/shared/stores/user';
import { api } from '@/services/api';
import { handleError } from '@/utils/handleError';
import { EnumsLocale } from '../../../../generated/api';

const props = defineProps({
  saveToDB: {
    type: Boolean,
    required: false,
    default: true,
  },
  showText: {
    type: Boolean,
    required: false,
    default: true,
  },
});

const userStore = useUserStore();
const primeVue = usePrimeVue();
const router = useRouter();
const toast = useToast();
const { t } = useI18n();

const selectedLanguage = ref<ILanguage>({
  code: EnumsLocale.Cs,
  text: '',
  flag: '',
});

onMounted(() => {
  initLanguage();
});

const isChanged = computed(() => {
  return selectedLanguage.value.code !== userStore.languageCode;
});

const initLanguage = () => {
  selectedLanguage.value = LanguagesCodebook.find((language: ILanguage) => {
    return userStore.languageCode === language.code;
  }) as ILanguage;
};

const saveLanguage = async (changeEvent: SelectChangeEvent) => {
  const payload = {
    locale: changeEvent.value.code,
  };
  try {
    await api.clientUserSettingsSetLocale(payload, {
      metadata: {
        accessToken: 'user',
      },
    });

    toast.add({
      severity: 'success',
      summary: t('global.selectLanguage.success'),
      life: 3000,
    });
  } catch (error: any) {
    handleError(error);
  }
};

const onChange = async (changeEvent: SelectChangeEvent) => {
  if (!isChanged.value) {
    return;
  }

  if (props.saveToDB) {
    await saveLanguage(changeEvent);
  }

  await userStore.setLanguage(changeEvent.value.code, primeVue);

  await router.push({ name: router.currentRoute.value.name as string, params: { lang: changeEvent.value.code } });
};
</script>
