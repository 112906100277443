import { RouteRecordRaw } from 'vue-router';
import { WIZARD_XML_FEED_ROUTES } from '@/modules/wizardXmlFeed/enums/RoutesEnum';
import FullPageLayout from '@/oldLayout/FullPageLayout.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: 'wizardXmlFeed',
    meta: {
      module: 'wizardXmlFeed',
      layout: FullPageLayout,
      quest: false,
      client: true,
    },
    children: [
      {
        path: '',
        name: WIZARD_XML_FEED_ROUTES.INDEX,
        redirect: { name: WIZARD_XML_FEED_ROUTES.XMLFEED },
        component: () => import('@/modules/wizardXmlFeed/pages/Index.vue'),
        children: [
          {
            path: 'firstStep',
            name: WIZARD_XML_FEED_ROUTES.XMLFEED,
            component: () => import('@/modules/wizardXmlFeed/pages/XmlFeed.vue'),
          },
        ],
      },
    ],
  },
];

export default routes;
